exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-solo-js": () => import("./../../../src/templates/ArticleSolo.js" /* webpackChunkName: "component---src-templates-article-solo-js" */),
  "component---src-templates-articles-filtered-by-tag-js": () => import("./../../../src/templates/ArticlesFilteredByTag.js" /* webpackChunkName: "component---src-templates-articles-filtered-by-tag-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/PrivacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-terms-of-use-js": () => import("./../../../src/templates/TermsOfUse.js" /* webpackChunkName: "component---src-templates-terms-of-use-js" */)
}

